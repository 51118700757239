export const Git_you_up_lyrics = `
[0] Never gonna give you up,
[2.6] Never gonna let you down,
[5.2] Never gonna run around and desert you.


[10.4] J'arrive pas à coder putain mon gars là je crois c'est chaud
[12.3] Au moins j'ai réussi à me log j'espère que j'ai pas 0
[15.2] J'suis venu depuis tôt ce matin tout ça pour que ça segfault
[17.5] J'ai autant de projet à 0 que mon code a des défauts

[19.5] 15h de logtime dans le cul frère j'veux rentrer chez moi
[22.2] 6 cafés dans le sang frère ouais je tremble des doigts
[24] J'ai pas honte de le dire, me demande pas pourquoi
[26.5] Mais j'ai vendu ma mère pour des points de coa

[29.3] Au compteur 30km j'ai pas de vago
[31.6] Regarde moi débarquer avec mon beau vélo
[34.1] Il est temps que je me pose 10min c'est chaud
[36.5] Toujours pas pris de douche dans le cluster du haut

[39.4] Ouais ouais ouais ouais, à tout nos piscineux
[41.8] On est en force avec vous les gars même si on a pas le droit de vous parler
[45.6] Brr brr brr


[47.4] Jamais j'irais sur Github,
[49.7] Jamais je pourrais me faire pull-up
[52.2] Putain relou faut que je change de fringue
[55] Et que je me lave

[56.9] Jamais j'irais sur Github,
[58.7] Jamais je ferais commande V
[61.7] Parce que si le bocal rodave
[64.2] Je suis viré

[67.5] Ouais t'as compris Benjamin
[69.2] Pas de Github
[70.7] Pas de Github fréro
[72.5] C'est pas bien, tu va te faire virer

[74.4] Ouais ouais ouais ouais ouais


[75.6] Fuck les gars du Z3
[76.6] Fuck les gars du Z1
[78.2] En direct du Z4
[79.2] Les autres c'est tous des chiens

[80.4] T'as pas passé le C4
[81.55] Même pas passé le C1
[82.7] Mon code est dans l'espace
[83.9] Segfault dès la ligne 1

[86] Ça va niquer ton pc oublie pas de te lockscreen
[88.4] Et t'auras même pas le droit
[89.6] D'enchainer avec des droites en vrai
[91.3] C'est pas top en dehors de l'école
[93.1] En effet je crois que c'est pas permis tout comme

[94.9] Fait attention où tu met ta gourde ou ta bouteille d'eau
[97.1] N'hésite pas à mettre sur le bureau celle des autres
[99.5] Bonne TIG nettoyer l'écran sale des gens
[102] Essaye de tricher et tu passeras un sale moment


[108.7] Jamais j'irais sur Github,
[111.1] Jamais je pourrais me faire pull-up
[113.5] Putain relou faut que je change de fringue
[116.1] Et que je me lave

[118.2] Jamais j'irais sur Github,
[120.5] Jamais je ferais commande V
[122.9] Parce que si le bocal rodave
[125.4] Je suis viré
`

export const CA42_lyrics = ` 
[2.3] Cluster du haut
[4.2] Cluster du haut
[6] Hey hey hey


[8.2] Cluster du haut ça sent pas bon c'est la mierda frero
[11.2] La TIG qui part gros j'en ai marre en plus mon code a eu 0
[15] Viens voir ta soeur dans mon cluster
[17.1] C'est cadencé je la code en C

[19.2] Pelo les gos c'est mort, j'ai trop de sueur
[21.5] Cluster du haut ça pue très fort


[23.3] Je suis en Z4 (Je suis en Z4)
[25.1] Je suis au C6 (Je suis au C6)
[26.9] J'alt f4 (J'alt f4)
[28.8] Je me fais TIG (Je me fais TIG)

[30.75] Je suis en Z4 (Je suis en Z4)
[32.7] Je suis au C6 (Je suis au C6)
[34.6] J'alt f4 (J'alt f4)
[36.6] Je me fais TIG

[38.5] Je suis dans le caca caca ca-42
[42.4] Je suis dans le caca caca ca (*pet*)
[46.1] Je suis dans le caca caca ca-42
[50] Je suis dans le caca caca ca (*pet*)


[54.2] Je sais pas coder et mes potes puent la merde
[56.4] Je pense que ça vient de leur aisselles
[58.2] Tout le monde charbonne
[58.9] Les points de coa merci la daronne

[62.4] Cool on a bientôt fini
[64] Merde y'a la norminette

[65.4] Odeur t'a peur
[66.2] Monster t'a peur
[67.3] Cluster du haut sent pas la fleur


[69.3] Je suis en Z4 (Je suis en Z4)
[71.2] Je suis au C6 (Je suis au C6)
[73.1] J'alt f4 (J'alt f4)
[75] Je me fais TIG (Je me fais TIG)

[77] Je suis en Z4 (Je suis en Z4)
[78.7] Je suis au C6 (Je suis au C6)
[80.9] J'alt f4 (J'alt f4)
[82.8] Je me fais TIG

[84.6] Je suis dans le caca caca ca-42
[88.5] Je suis dans le caca caca ca (*pet*)
[92.2] Je suis dans le caca caca ca-42
[96] Je suis dans le caca caca ca-42


[100.2] Le git add le git clone le git push c'est magique
[102.05] Piscine horrible
[103.2] J'habite ici
[104.2] Mon code trop de ligne
[105.25] Je me barre d'ici
[106.2] J'arrive chez moi j'ai pas le wifi

[108] Le code à chier la moulinette va encore me taper
[111.2] Exam raté mon code claqué je crois fréro que je vais y aller


[115.2] Je suis en Z4 (Je suis en Z4)
[117.3] Je suis au C6 (Je suis au C6)
[119.1] J'alt f4 (J'alt f4)
[121.2] Je me fais TIG (Je me fais TIG)

[123] Je suis en Z4 (Je suis en Z4)
[124.8] Je suis au C6 (Je suis au C6)
[127] J'alt f4 (J'alt f4)
[128.8] Je me fais TIG

[130.5] Je suis dans le caca caca ca-42
[134.6] Je suis dans le caca caca ca (*pet*)
[138.3] Je suis dans le caca caca ca-42
[142.1] Je suis dans le caca caca ca-42

[149] 42
[152.8] 42
`

export const Elle_code_bien_sa_mere_lyrics = `
[13.7] Je l'ai corrigé à midi pile
[16.3] hé hé hé


[18.1] Je l'ai corrigé à midi pile étourdit
[20.5] Son code est parfait et en plus elle est polie
[22.7] Première du ladder c'est son objectif
[25.1] Même son malloc elle a pas oublié de le free

[27.2] Faudrait qu'elle vienne m'aider là, j'y arrive pas
[29.6] Et j'aimerais push avant de partir ce soir
[31.7] Elle a reglé mon segfault en seulement 1 clic là
[34.2] Elle code trop bien pour elle c'est facile ça


[40] Elle code bien sa mère, bien sa mère


[44.8] Elle code bien sa mère, elle maitrise Vim
[49.4] Gros laisse la faire, elle free tout les leaks
[53.9] Demain c'est maintenant, je suis co depuis hier matin (hier matin)
[58.6] Malloc de 500, elle code split sans les mains (split sans les mains)


[64.1] Elle arrive en exam elle organise son screen
[66.2] Une fleur parmi les puants dans le cluster du haut (ça pue)
[68.7] Elle sort au bout d'une demi heure elle se fait applaudir
[70.8] C'est la seule qui avait pas oublié son stylo

[73.8] Elle code bien sa mère sur la vie de ma mère pour elle C10 c'est pas assez
[78.3] Elle code bien sa mère sur la vie de ma mère pour elle C12 c'est pas assez
[82.8] Ohhh, c'est la queen en C, son projet est tellement clean j'ai cru que c'était de l'anglais
[87.7] Ohhh, c'est la queen en C, son projet est tellement clean j'ai cru que c'était de l'anglais


[90.5] Elle code bien sa mère, bien sa mère
[99.6] Elle code bien sa mère, bien sa mère


[108.7] Elle code bien sa mère, elle maitrise Vim
[113.2] Gros laisse la faire, elle free tout les leaks
[117.7] Demain c'est maintenant, je suis co depuis hier matin (hier matin)
[122.4] Malloc de 500, elle code split sans les mains (split sans les mains)

[127] Elle code bien sa mère, elle maitrise Vim (Vim vim vim vim)
[131.5] Gros laisse la faire, elle free tout les leaks (tout les leaks, tout les leaks)
[136.2] Demain c'est maintenant, je suis co depuis hier matin (hier matin)
[140.7] Malloc de 500, elle code split sans les mains (split sans les mains)

[145.1] Skrrrr paa
[148.2] Je suis co depuis hier matin
[150] Po po po
[152.1] Elle code bien sa mère
[154.3] Sa mère, sa mère
[157] Prrr ra
[158.4] Ouhouhouh
[159.6] Sa mère
[160.1] Elle code trop bien !
[161.9] Sa mère
[163.7] Skrrrrrrrrrr
`

export const ft_crepesstyle_lyrics = `
[5.7] C'est le ft_crepes, ft_crepes


[14.1] J'arrive au campus et aujourd'hui c'est la D là
[17.8] J'ai oublié chez moi mon tupperware au poulet soja
[21.4] Si seulement y'avait quelque chose que je pouvais manger ici
[24.8] Parce qu'il est vide mon estomac


[28.4] Ne t'inquiètes pas, on est mardi et on a fait la pâte on est là pour toi
[33.4] Les appareils chauffent pour ta plus grande joie des bonnes crêpes mon gars
[37] Elle sont grandes elles font la taille d'une pizza
[39.3] On est adorable, service 5 étoiles


[42.8] Y'a du Nutella, chantilly banane
[46.6] Sucre citron (Hey)
[48.1] Crême de marron (Hey)
[50.2] Sirop d'érable, pâte de spéculos
[53.8] Confiture (Hey)
[55.4] De fraise bien sûr (Het)
[57.5] Et sans oublier le beurre de cacahuète

[65.7] C'est le ft_crepes, ft_crepes
[70.7] C'est, c'est, c'est, c'est, c'est le ft_crepes, ft_crepes
[78.1] C'est, c'est, c'est, c'est, c'est le ft_crepes

[81.7] Hey, on est mardi
[87.7] C'est le ft_crepes
[88.8] Hey, tout est gratuit
[92.7] (Bon après faut qu'on paye les courses donc hésite pas à tips mon pote)


[96.2] Réveil 7h j'arrive pile à l'heure pour l'examen
[99.4] Sorti au bout de 3h rien réussi mon code est pas bien
[103.3] J'avais relu les projets mais apparement pas assez
[106.6] J'ai tout foiré, je vais me flinguer


[110.4] Ne t'inquiètes pas, on est mardi et on a fait la pâte on est là pour toi
[115.3] Les appareils chauffent pour ta plus grande joie des bonnes crêpes mon gars
[118.9] Elle sont grandes elles font la taille du Kenya
[121.1] On est adorable, service 5 étoiles


[124.7] Y'a du Nutella, chantilly banane
[128.5] Sucre citron (Hey)
[130.2] Crême de marron (Hey)
[132] Sirop d'érable, pâte de spéculos
[135.7] Confiture (Hey)
[137.3] De fraise bien sûr
[139.3] Et sans oublier le beurre de cacahuète

[147.6] C'est le ft_crepes, ft_crepes
[152.5] C'est, c'est, c'est, c'est, c'est le ft_crepes, ft_crepes
[159.8] C'est, c'est, c'est, c'est, c'est le ft_crepes

[163.4] Hey, on est mardi
[169.4] C'est le ft_crepes
[170.7] Hey, tout est gratuit
[174.8] (Bon après si t'es vraiment cool on prend carte bleu et espèces)


[178.1] C'est si bon, ouais je me suis régalé
[181.2] Je crois je crois que je vais leur donner plein d'argent
[185.2] C'est si bon, putain c'était si bon
[188.5] C'est sûr c'est sûr que le ft_crepes c'est des champions
[191] (OUAIS C'EST DES CHAMPIONS)

[193.1] C'est le ft_crepes
[194.3] hey, hey, hey, hey, hey, hey, hey, on est mardi
[202.2] C'est le ft_crepes
[203.5] Hey, tout est gratuit
[207.1] (Bon après si tu veux tips on va pas se plaindre)

[209.1] hey, hey, hey, hey, hey, hey, hey
[211.4] C'est le ft_crepes
`

export const Tout_niquer_lyrics = `
[8.4] Les seules lignes de C que je tape c'est celles dans mon projet
[10.4] Et c'est pas de la drogue quand je te parle de coder
[12.4] Mkdir je m'allonge sur mon dossier
[14.5] Force et tu verras que le code c'est pas sorcier

[16.3] Fuck les pointeurs je les garde en mémoire
[18.5] Je vis la nuit, j'ai moins peur quand il fait noir
[20.5] Mon texte et mon code je les ai testé
[22.3] Et prenez des douches, les salles sont empestées

[23.9] Préparez vous bien j'ai écrit mon texte sur Vim
[25.8] On est beaucoup trop chauds fréro allume la clim
[27.6] Tu sais qu'on est calé, on est les rois du C
[29.6] Quand on rend nos projets t'inquiète même pas ils sont clean (ils sont quoi ?)

[32.7] On est des pointeurs sur le succès
[33.9] Ça fait 4 jours qu'on a appris à faire du C
[35.8] Git add, git clone, git push, toi même tu sais
[37.8] Monster dans la gorge je décolle comme une fusée


[40.2] N'oublie pas les corrections (ah fait chier où est ma clé USB)
[44.2] Avance bien sur tout tes projets (Nan attends attends je vais m'ambiancer)


[48.1] N'oublie pas les corrections (C'est la base en piscine)
[51.7] Avance bien sur tout tes projets (C'est important aussi)
[56] Et attention à la moulinette, à la norminette et à ne pas tricher
[59.9] Car si tu triche, tu va te faire tej et t'auras tout niqué


[63.6] 100 balles le PC et 10 balles le moniteur
[65.5] 4To de code c'est beaucoup trop pour le core 2 processeur
[67.5] Tout seul chez moi, pas de professeur
[69.1] L'année prochaine je prend la place du directeur

[71.5] Clavier, souris
[73.5] Purée mon code est pourri
[75.1] Automatique, je suis le nouveau Wall-E
[77] Ma corbeille est pleine bienvenu à la déchetterie


[79.85] N'oublie pas les corrections (ah fait chier où est ma clé USB)
[84] Avance bien sur tout tes projets (Nan attends attends je vais m'ambiancer)


[87.8] N'oublie pas les corrections (C'est la base en piscine)
[91.7] Avance bien sur tout tes projets (C'est important aussi)
[95.7] Et attention à la moulinette, à la norminette et à ne pas tricher
[99.7] Car si tu triche, tu va te faire tej et t'auras tout niqué


[103.6] Fin de journée, je suis trop claqué (ouais trop claqué)
[107.5] J'ai bien bossé sur mon projet de C (sur mon projet de C)
[111] Même si déconcentré, déconcentré (Là je sais pas quoi faire ouais ouais)
[114.9] Cluster du haut ça sent, ça sent le pet (Ouais ça pue de ouf)


[119.7] Bertrand Goudard reporter LCI
[121.7] Nous nous trouvons dans la banlieue de Lyon à charbonnières les bains
[124.5] Près du campus numérique où à été reporté un feu
[127] En effet 4 étudiants ont complètement détruit une prod
[130.2] Et d'ailleurs j'en ai entendu un petit peu c'était vraiment pas mal
[133.4] C'était Bertrand Goudard, à vous les studios
`

export const Beurre_perime = `
[5.3] Salut les crêpes y'aura plus t'es tout seul
[8.1] Et on a fait du bénef sur ta gueule
[10.3] Nananana on t'a niqué
[12.6] Les crêpes sont au beurre périmé

[15.5] Salut les crêpes y'aura plus t'es tout seul
[18.5] Et on a fait du bénef sur ta gueule
[21] Nananana on t'a niqué
[23.1] Les crêpes sont au beurre périmé

[26.40] En vrai ça nous prend du temps parce que la farine on la coupe au plâtre
[31] On fait genre qu'on s'y connait
[32.4] On fait genre qu'on est calé
[33.8] Mais on sait pas faire des œufs au plat

[36.75] Tu te disais "oh les pauvres,
[38.85] Je vais les aider ils le méritent bien"
[41.17] Mais on chill, on est pété d'thunes
[44.15] On part à Ibiza après-demain

[46.9] Les œufs, ils ont pas le label bio
[49.5] Le lait, on le met pas au frigo
[52.18] Le beurre, d'où il sort, on sait pas trop
[55] Et le nutella on le coupe à l'eau

[58.1] Salut les crêpes y'aura plus t'es tout seul
[60.5] Et on a fait du bénef sur ta gueule
[63.4] Nananana on t'a niqué
[65.6] Les crêpes sont au beurre périmé

[68.70] Salut les crêpes y'aura plus t'es tout seul
[71.5] Et on a fait du bénef sur ta gueule
[74.05] Nananana on t'a niqué
[76.17] Les crêpes sont au beurre périmé

[79.3] Je tiens vraiment à te remercier
[81.35] Pour tout ces dons généreux
[84.05] J'ai payé mes vacances, j'ai payé mon loyer
[86.77] Je me suis même acheté l'iMac 2

[89.6] Si l'bocal faisait pas la queue
[91.75] C'est parce que des tips ils en donnent plus
[94.08] Nos crêpes sont pas chères pleines de parasites
[97] On dirait que c'est un marché aux puces

[99.75] Les œufs, ils ont pas le label bio
[102.35] Le lait, on le met pas au frigo
[105] Le beurre, d'où il sort, on sait pas trop
[107.7] Et le nutella on le coupe à l'eau

[110.9] Salut les crêpes y'aura plus t'es tout seul
[113.55] Et on a fait du bénef sur ta gueule
[116.3] Nananana on t'a niqué
[118.32] Les crêpes sont au beurre périmé

[121.58] Salut les crêpes y'aura plus t'es tout seul
[124.2] Et on a fait du bénef sur ta gueule
[126.86] Nananana on t'a niqué
[128.87] Les crêpes sont au beurre périmé

[131.8] Ça coûte vraiment que 8 euros
[134.24] De faire 200 crêpes bande de blaireaux
[136.8] Tu veux qu'on t'rende tes sous pélo ?
[139.58] Dommage tout est parti dans l'resto

[142.26] Ça coûte vraiment que 8 euros
[144.83] De faire 200 crêpes bande de blaireaux
[147.38] Tu veux qu'on t'rende tes sous pélo ?
[150] Aucune chance mon pti jambonneau hey

[152.8] Nanana nananana nananananana
[157.15] On a pris ta moula
[158.17] Nanana nananana nananananana
[162.45] On a pris ta moula

[163.85] Salut les crêpes y'aura plus t'es tout seul
[166.35] Et on a fait du bénef sur ta gueule
[168.8] Nananana on t'a niqué
[171] Les crêpes sont au beurre périmé

[174.1] Salut les crêpes y'aura plus t'es tout seul
[176.9] Et on a fait du bénef sur ta gueule
[179.5] Nananana on t'a niqué
[181.6] Les crêpes sont au beurre périmé
`